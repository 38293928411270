import * as React from 'react'

import { Grid } from '@mui/material'

import { AppContainer, SeoHeaders } from 'shared/components'

import { SwapForm } from '../swap_form'

import type { RouteComponentProps } from '@reach/router'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Swap = (props: RouteComponentProps) => (
  <React.Fragment>
    <SeoHeaders title='Convertir' />
    <Grid
      container
      maxWidth='sm'
      mx='auto'
    >
      <AppContainer sx={{ p: 3 }}>
        <SwapForm />
      </AppContainer>
    </Grid>
  </React.Fragment>
)

export default Swap
