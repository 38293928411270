import * as React from 'react'

import { useQuery } from '@apollo/client'

import { Loading, SeoHeaders } from 'shared/components'
import { MARKET_ASSETS_QUERY } from 'shared/queries'
import { hasCrypto } from 'shared/services'
import { PortfolioView } from 'shared/views'

import type { RouteComponentProps } from '@reach/router'
import type { MarketAssetsData,  MarketAssetsVars } from 'shared/queries'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Portfolio = (props: RouteComponentProps) => {
  const { loading, data } = useQuery<MarketAssetsData, MarketAssetsVars>(MARKET_ASSETS_QUERY)

  const marketAssets = data?.marketAssets || []
  const graphUnits = hasCrypto(marketAssets) ? ['USDT', 'BTC', 'CLP'] : ['USD', 'CLP']

  return loading ? (
    <Loading />
  ) : (
    <React.Fragment>
      <SeoHeaders title='Portafolio' />
      <PortfolioView graphUnits={graphUnits} />
    </React.Fragment>
  )
}

export default Portfolio
