import * as React from 'react'

import { useQuery } from '@apollo/client'
import { AccountBalance, AccountBalanceWallet } from '@mui/icons-material'
import { Button, Grid, Stack, Typography } from '@mui/material'
import { Link, type RouteComponentProps } from '@reach/router'

import { AppContainer, Loading, SeoHeaders } from 'shared/components'
import { MARKET_ASSETS_QUERY } from 'shared/queries'
import { hasCrypto } from 'shared/services'

import type { MarketAssetsData, MarketAssetsVars } from 'shared/queries'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Withdraw = (props: RouteComponentProps) => {
  const { loading, data } =
    useQuery<MarketAssetsData, MarketAssetsVars>(MARKET_ASSETS_QUERY)

  const marketAssets = data?.marketAssets || []

  return loading ? (
    <Loading />
  ) : (
    <React.Fragment>
      <SeoHeaders title='Retirar' />
      <Grid
        container
        maxWidth='sm'
        mx='auto'
      >
        <AppContainer sx={{ p: 3 }}>
          <Stack
            alignItems='center'
            justifyContent='center'
            spacing={2}
            mx='auto'
            maxWidth='420px'
          >
            <Typography
              variant='h6'
              component='p'
              textAlign='center'
              pb={1}
            >
              Retirar activos
            </Typography>
            {hasCrypto(marketAssets) && (
              <Button
                component={Link}
                to='./blockchain'
                startIcon={<AccountBalanceWallet />}
                variant='contained'
                size='large'
                fullWidth
              >
                Hacia billetera blockchain
              </Button>
            )}
            <Button
              component={Link}
              to='./bank'
              startIcon={<AccountBalance />}
              variant='contained'
              size='large'
              fullWidth
            >
              Hacia cuenta bancaria
            </Button>
          </Stack>
        </AppContainer>
      </Grid>
    </React.Fragment>
  )
}

export default Withdraw
