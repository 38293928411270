import * as React from 'react'

import { useMutation, useQuery } from '@apollo/client'
import {
  Button,
  Divider,
  InputAdornment,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { CheckboxWithLabel, Select } from 'formik-mui'
import * as Yup from 'yup'

import {
  AssetBadge,
  ButtonContainer,
  ButtonsContainer,
  Currency,
  ErrorDisplay,
  Loading,
  NotificationConfirmation,
} from 'shared/components'
import { CurrencyField } from 'shared/forms'
import {
  MARKET_ASSETS_QUERY,
  NOTIFY_USER_REQUEST_MUTATION,
  USER_OPERATIONS_QUERY,
} from 'shared/queries'
import {
  asOperationsArray,
  getTotalAmounts,
  isValidAssetAmount,
  setFormError,
  translateError,
} from 'shared/services'

import type { FormikProps } from 'formik'
import type {
  MarketAsset,
  MarketAssetsData,
  MarketAssetsVars,
  NotifyUserRequestData,
  NotifyUserRequestVars,
  UserOperationsData,
  UserOperationsVars,
} from 'shared/queries'

type FormValues = {
  fromSymbol: string
  fromAmount: number
  useMaxAmount: boolean
  toSymbol: string
}

const initialValues: FormValues = ({
  fromSymbol: '',
  fromAmount: 0,
  useMaxAmount: false,
  toSymbol: '',
})

type TotalAmounts = {
  [symbol: string]: number
}

const validationSchema = (totalAmounts: TotalAmounts): Yup.SchemaOf<FormValues> => (
  Yup.object().shape({
    fromSymbol: Yup.string()
      .required('Este campo es obligatorio')
      .nullable(),
    fromAmount: Yup.number()
      .typeError('Debes ingresar un número')
      .required('Este campo es obligatorio')
      .positive('Debes ingresar un monto mayor a cero')
      .test(
        'validFormat',
        'Introduce un número con máximo 6 decimales',
        (value, context) => context.parent.useMaxAmount || isValidAssetAmount(value),
      )
      .test(
        'maxAmount',
        'Debes ingresar un monto menor o igual a tu balance',
        (value, context) => {
          if (!value) {
            return false
          }

          const fromSymbol = context.parent.fromSymbol

          if (!fromSymbol) {
            return false
          }

          const totalAmount = totalAmounts[fromSymbol]

          if (!totalAmount) {
            return false
          }

          return value <= totalAmount
        },
      ),
    useMaxAmount: Yup.boolean()
      .required('Este campo es obligatorio'),
    toSymbol: Yup.string()
      .required('Este campo es obligatorio'),
  })
)

type InnerFormProps = FormikProps<FormValues> & {
  marketAssets: MarketAsset[]
  totalAmounts: TotalAmounts
}

export const InnerForm = ({
  isSubmitting,
  isValid,
  setFieldTouched,
  setFieldValue,
  status,
  submitForm,
  values,
  marketAssets,
  totalAmounts,
}: InnerFormProps) => {
  const { fromSymbol, useMaxAmount } = values

  const onFromSymbolChange = () => {
    setFieldValue('fromAmount', 0)
    setFieldTouched('fromAmount', false, false)
    setFieldValue('useMaxAmount', false)
    setFieldTouched('useMaxAmount', false, false)
    setFieldValue('toSymbol', '')
    setFieldTouched('toSymbol', false, false)
  }

  const onUseMaxAmountChange = () => {
    if (!fromSymbol) {
      return
    }

    const newUseMaxAmount = !useMaxAmount
    setFieldValue('useMaxAmount', newUseMaxAmount)
    setFieldValue('fromAmount', newUseMaxAmount ? totalAmounts[fromSymbol] : 0)
    setFieldTouched('fromAmount', false, false)
  }

  return (
    <Form>
      <Stack
        spacing={2}
        marginTop={2}
      >
        <Divider sx={{ my: 1 }}>De</Divider>
        <Field
          required
          name='fromSymbol'
          type='text'
          label='Selecciona un activo'
          onChange={onFromSymbolChange}
          component={Select}
        >
          {marketAssets.map((asset) => (
            <MenuItem
              key={asset.symbol}
              value={asset.symbol}
            >
              <AssetBadge
                symbol={asset.symbol}
                height={20}
                style={{ marginRight: 10 }}
              />
              {asset.name}
            </MenuItem>
          ))}
        </Field>
        <CurrencyField
          name='fromAmount'
          label='Monto a convertir'
          disabled={!fromSymbol || useMaxAmount}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <small>{fromSymbol}</small>
              </InputAdornment>
            ),
          }}
          digits={6}
          positive
        />
        <Field
          required
          component={CheckboxWithLabel}
          disabled={!fromSymbol}
          onChange={onUseMaxAmountChange}
          type='checkbox'
          name='useMaxAmount'
          Label={{
            sx: { mt: -1, mb: 1 },
            label: (
              <React.Fragment>
                Usar el máximo disponible (aprox.&nbsp;
                <Currency
                  currency={fromSymbol}
                  value={totalAmounts[fromSymbol]}
                />)
              </React.Fragment>
            ),
          }}
        />
        <Divider sx={{ my: 1 }}>A</Divider>
        <Field
          required
          name='toSymbol'
          type='text'
          label='Selecciona un activo'
          component={Select}
        >
          {marketAssets
            .filter((asset) => asset.symbol !== fromSymbol)
            .map((asset) => (
              <MenuItem
                key={asset.symbol}
                value={asset.symbol}
              >
                <AssetBadge
                  symbol={asset.symbol}
                  height={20}
                  style={{ marginRight: 10 }}
                />
                {asset.name}
              </MenuItem>
            ))}
        </Field>
      </Stack>
      <ErrorDisplay
        errorMsg={status?.errorMsg}
        mt={3}
      />
      <ButtonsContainer sx={{ alignItems: 'flex-end', mt: 3 }}>
        <ButtonContainer xs={12}>
          <Button
            fullWidth
            disabled={isSubmitting || !isValid}
            onClick={submitForm}
            variant='contained'
            type='submit'
          >
            Continuar
          </Button>
        </ButtonContainer>
      </ButtonsContainer>
    </Form>
  )
}

export const SwapForm = () => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)
  const [showConfirmation, setShowConfirmation] = React.useState(false)
  const [message, setMessage] = React.useState<string[]>([])

  const { loading: assetsLoading, data: assetsData } =
    useQuery<MarketAssetsData, MarketAssetsVars>(MARKET_ASSETS_QUERY)

  const { loading: operationsLoading, data: operationsData } =
    useQuery<UserOperationsData, UserOperationsVars>(USER_OPERATIONS_QUERY)

  const symbols = assetsData?.marketAssets.map(({ symbol }) => symbol) || []
  const operations = asOperationsArray(operationsData?.userOperations)
  const totalAmounts = getTotalAmounts(symbols, operations)

  const [notifyUserRequest] =
    useMutation<NotifyUserRequestData, NotifyUserRequestVars>(
      NOTIFY_USER_REQUEST_MUTATION, {
        errorPolicy: 'all',
      })

  if (assetsLoading || operationsLoading) {
    return <Loading />
  }

  const handleSubmit = async (values: FormValues) => {
    const response = await notifyUserRequest({
      variables: {
        notificationType: 'SWAP',
        content: [values.fromSymbol, values.fromAmount.toString(), values.toSymbol],
      },
    })

    if (response.data?.notifyUserRequest) {
      setMessage(response.data.notifyUserRequest)
      setShowConfirmation(true)
      return
    }

    setFormError(formRef, translateError(response))
  }

  return showConfirmation ? (
    <NotificationConfirmation message={message} />
  ) : (
    <React.Fragment>
      <Typography
        variant='h6'
        component='span'
        textAlign='center'
        gutterBottom
      >
        Convertir activos
      </Typography>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema(totalAmounts)}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <InnerForm
            marketAssets={assetsData?.marketAssets || []}
            totalAmounts={totalAmounts}
            {...props}
          />
        )}
      </Formik>
    </React.Fragment>
  )
}
