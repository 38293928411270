import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Grid } from '@mui/material'

import {
  AppContainer,
  BackButton,
  ButtonsContainer,
  EarningsBalance,
  FomoText,
  GraphUnitButtons,
  MainBalance,
  OperationsList,
  PricesGraph,
  RangeButtons,
  VariationBalance,
} from '../components'
import { ASSETS_PRICES_QUERY, USER_OPERATIONS_QUERY } from '../queries'
import {
  asOperationsArray,
  getBalanceGraphData,
  getPriceGraphData,
  getUnspentEarnedAmount,
  onlyUnique,
  useHasApyWithSymbol,
  withSymbol,
} from '../services'

import type {
  AssetsPricesData,
  AssetsPricesVars,
  LongRange,
  UserOperationsData,
  UserOperationsVars,
} from '../queries'

const graphHeight = 280

export type PortfolioDetailsViewProps = {
  symbol?: string
  graphUnits: string[]
}

export const PortfolioDetailsView = (props: PortfolioDetailsViewProps) => {
  const symbol = props.symbol || ''
  const graphUnits = onlyUnique([...props.graphUnits, symbol])

  const [graphUnit, setGraphUnit] = React.useState<string>(graphUnits[0])
  const [range, setRange] = React.useState<LongRange>('24h')

  const { loading: hasApyLoading, hasApy } = useHasApyWithSymbol(symbol)

  const { loading: pricesLoading, data: pricesData } =
    useQuery<AssetsPricesData, AssetsPricesVars>(ASSETS_PRICES_QUERY, {
      variables: {
        quoteSymbol: graphUnit,
        symbols: [symbol],
        range,
      },
    })

  const { loading: operationsLoading, data: operationsData } =
    useQuery<UserOperationsData, UserOperationsVars>(USER_OPERATIONS_QUERY)

  const assetPrices = pricesData ? pricesData.assetsPrices : []

  const assetOperations = withSymbol(
    asOperationsArray(operationsData?.userOperations), symbol,
  )
  const hasNoOperations = (!operationsLoading && assetOperations.length === 0)

  const priceGraphData = getPriceGraphData(assetPrices, symbol)
  const balanceGraphData = getBalanceGraphData(priceGraphData, assetOperations)

  const earnedAmount = getUnspentEarnedAmount(symbol, assetOperations)

  return (
    <Grid
      container
      spacing={3}
    >
      <MainBalance
        loading={pricesLoading || operationsLoading}
        currency={graphUnit}
        amount={balanceGraphData.lastValue}
      />
      {hasApy ? (
        <EarningsBalance
          loading={hasApyLoading || pricesLoading || operationsLoading}
          currency={graphUnit}
          amount={earnedAmount * (priceGraphData.lastValue || 0)}
        />
      ) : (
        <VariationBalance
          loading={hasApyLoading || pricesLoading || operationsLoading}
          currency={graphUnit}
          range={range}
          amount={balanceGraphData.variation}
        />
      )}
      <ButtonsContainer>
        <BackButton
          text='Portafolio'
          containerProps={{ xs: true }}
        />
        <GraphUnitButtons
          disabled={pricesLoading}
          keys={graphUnits}
          graphUnit={graphUnit}
          setGraphUnit={setGraphUnit}
        />
        <RangeButtons<LongRange>
          disabled={pricesLoading || hasNoOperations}
          keys={['24h', '7d', '30d', '1y', 'all']}
          range={range}
          setRange={setRange}
        />
      </ButtonsContainer>
      <AppContainer sx={{ height: graphHeight, py: 1 }}>
        {hasNoOperations ? (
          <FomoText symbol={symbol} />
        ) : (
          <PricesGraph
            currency={graphUnit}
            data={balanceGraphData.points}
            ath={balanceGraphData.ath}
            atl={balanceGraphData.atl}
          />
        )}
      </AppContainer>
      <OperationsList
        loading={pricesLoading || operationsLoading}
        operations={assetOperations}
      />
    </Grid>
  )
}
