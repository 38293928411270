import * as React from 'react'

import { useQuery } from '@apollo/client'

import { Loading, SeoHeaders } from 'shared/components'
import { FUNDS_QUERY } from 'shared/queries'
import { FundsView } from 'shared/views'

import type { RouteComponentProps } from '@reach/router'
import type { FundsData, FundsVars } from 'shared/queries'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Funds = (props: RouteComponentProps) => {
  const { loading, data } = useQuery<FundsData, FundsVars>(FUNDS_QUERY)

  const funds = data?.funds.funds || []
  const totalBalance = data?.funds.totalBalance || 0

  return loading ? (
    <Loading />
  ) : (
    <React.Fragment>
      <SeoHeaders title='Fondos' />
      <FundsView
        funds={funds}
        totalBalance={totalBalance}
      />
    </React.Fragment>
  )
}

export default Funds
