import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Grid } from '@mui/material'

import { AppContainer, Loading, SeoHeaders } from 'shared/components'
import { BULK_PURCHASE_CONFIG_QUERY } from 'shared/queries'

import BulkPurchaseCreator from '../bulk_purchase_creator'

import type { RouteComponentProps } from '@reach/router'
import type {
  BulkPurchaseConfigData,
  BulkPurchaseConfigVars,
} from 'shared/queries'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Quote = (props: RouteComponentProps) => {
  const { loading, data } =
    useQuery<BulkPurchaseConfigData, BulkPurchaseConfigVars>(BULK_PURCHASE_CONFIG_QUERY)

  const config = data?.bulkPurchaseConfig

  return (loading) ? (
    <Loading />
  ) : (
    <React.Fragment>
      <SeoHeaders title='Comprar' />
      <Grid
        container
        maxWidth='sm'
        mx='auto'
      >
        <AppContainer sx={{ p: 3 }}>
          <BulkPurchaseCreator config={config!} />
        </AppContainer>
      </Grid>
    </React.Fragment>
  )
}

export default Quote
