import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Grid } from '@mui/material'

import {
  AssetsDisplay,
  ButtonsContainer,
  RangeButtons,
} from '../components'
import { ASSETS_PRICES_QUERY, MARKET_ASSETS_QUERY } from '../queries'

import type {
  AssetsPricesData,
  AssetsPricesVars,
  MarketAssetsData,
  MarketAssetsVars,
  ShortRange,
} from '../queries'

export const MarketView = () => {
  const [range, setRange] = React.useState<ShortRange>('24h')

  const { loading: assetsLoading, data: assetsData } =
    useQuery<MarketAssetsData, MarketAssetsVars>(MARKET_ASSETS_QUERY)

  const marketAssets = assetsData?.marketAssets || []
  const symbols = marketAssets.map((marketAsset) => marketAsset.symbol)

  const { loading: pricesLoading, data: pricesData } =
    useQuery<AssetsPricesData, AssetsPricesVars>(ASSETS_PRICES_QUERY, {
      variables: {
        quoteSymbol: 'USD',
        symbols,
        range,
      },
    })

  const assetsPrices = pricesData?.assetsPrices || []

  return (
    <Grid
      container
      spacing={3}
    >
      <ButtonsContainer>
        <RangeButtons<ShortRange>
          disabled={pricesLoading}
          keys={['24h', '7d']}
          range={range}
          setRange={setRange}
        />
      </ButtonsContainer>
      <AssetsDisplay
        loading={assetsLoading || pricesLoading}
        marketAssets={marketAssets}
        assetPrices={assetsPrices}
      />
    </Grid>
  )
}
