import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Typography } from '@mui/material'

import { Loading, SeoHeaders } from 'shared/components'
import { FUND_QUERY, USER_OPERATIONS_QUERY } from 'shared/queries'
import { asOperationsArray } from 'shared/services'
import { FundDetailsView } from 'shared/views'

import type { RouteComponentProps } from '@reach/router'
import type {
  FundData,
  FundVars,
  UserOperationsData,
  UserOperationsVars,
} from 'shared/queries'

type FundDetailsProps = RouteComponentProps & {
  id?: string
}

const FundsDetails = (props: FundDetailsProps) => {
  const fundId = props.id || ''

  const { loading: fundLoading, data: fundData } = useQuery<FundData, FundVars>(FUND_QUERY, {
    variables: {
      fundId,
    },
  })

  const { loading: operationsLoading, data: operationsData } =
    useQuery<UserOperationsData, UserOperationsVars>(USER_OPERATIONS_QUERY)

  if (fundLoading || operationsLoading) {
    return <Loading />
  }

  const fund = fundData?.fund
  const operations = asOperationsArray(operationsData?.userOperations)

  return fund ? (
    <React.Fragment>
      <SeoHeaders title={`Fondos - (${fund.name})`} />
      <FundDetailsView
        fund={fund}
        operations={operations}
      />
    </React.Fragment>
  ) : (
    <React.Fragment>
      <SeoHeaders title='Fondo no encontrado' />
      <Typography
        variant='h5'
        textAlign='center'
      >
        Fondo no encontrado
      </Typography>
    </React.Fragment>
  )
}

export default FundsDetails
