import * as React from 'react'

import { Grid } from '@mui/material'

import {
  BackButton,
  FundDisplay,
  GridDivider,
  OperationsList,
} from '../components'
import { Fund, UserOperation } from '../queries'
import { withFund } from '../services'
import { FundBalance } from '../views'

type FundDetailsProps = {
  fund: Fund
  operations: UserOperation[]
}

export const FundDetailsView = ({
  fund,
  operations,
}: FundDetailsProps) => (
  <React.Fragment>
    <Grid
      container
      spacing={3}
      paddingBottom={12}
    >
      <FundBalance
        loading={false}
        descriptionText='Monto total'
        currency='USD'
        digits={2}
        value={fund.balance}
      />
      <FundBalance
        loading={false}
        descriptionText='Valor cuota'
        currency='USD'
        digits={2}
        value={fund.sharePrice}
      />
      <BackButton
        text='Fondos'
        containerProps={{ xs: true }}
      />
      <FundDisplay
        fund={fund}
        graphType='COMPOSITION'
      />
      <GridDivider>Historial</GridDivider>
      <OperationsList operations={withFund(operations, fund.name)} />
    </Grid>
  </React.Fragment>
)
