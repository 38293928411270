import * as React from 'react'

import { useQuery } from '@apollo/client'
import {
  AccountBalance,
  AttachMoney,
  Business,
  CreditCard,
  Download,
  History as HistoryIcon,
  Mail,
  RequestQuote,
  ShowChart,
  SwapCalls,
  Upload,
} from '@mui/icons-material'
import { Link, Redirect, Router, useLocation } from '@reach/router'

import {
  DrawerDivider,
  DrawerList,
  DrawerListItem,
  Loading,
  UserNotificationsIcon,
} from 'shared/components'
import { MARKET_ASSETS_QUERY, USER_NOTIFICATIONS_QUERY } from 'shared/queries'
import { hasCrypto } from 'shared/services'

import BankAccounts from '../components/views/bank_accounts'
import BulkPurchases from '../components/views/bulk_purchases'
import Deposit from '../components/views/deposit'
import Documents from '../components/views/documents'
import FundsDetails from '../components/views/fund_details'
import FundPurchase from '../components/views/fund_purchase'
import FundSale from '../components/views/fund_sale'
import Funds from '../components/views/funds'
import History from '../components/views/history'
import Market from '../components/views/market'
import MarketDetails from '../components/views/market_details'
import NotFound from '../components/views/not_found'
import Notifications from '../components/views/notifications'
import Portfolio from '../components/views/portfolio'
import PortfolioDetails from '../components/views/portfolio_details'
import Purchase from '../components/views/purchase'
import Quote from '../components/views/quote'
import Security from '../components/views/security'
import Signature from '../components/views/signature'
import Support from '../components/views/support'
import Swap from '../components/views/swap'
import Withdraw from '../components/views/withdraw'
import WithdrawBank from '../components/views/withdraw_bank'
import WithdrawBlockchain from '../components/views/withdraw_blockchain'
import WithdrawalAddress from '../components/views/withdrawal_address'
import Layout from '../layouts/app'

import type {
  MarketAssetsData,
  MarketAssetsVars,
  UserNotificationsData,
  UserNotificationsVars,
} from 'shared/queries'

const App = () => {
  const pathname = useLocation().pathname.replace(/\/$/, '')
  const basepath = '/app'

  const { loading: assetsLoading, data: assetsData } =
    useQuery<MarketAssetsData, MarketAssetsVars>(MARKET_ASSETS_QUERY)

  const marketAssets = assetsData?.marketAssets || []

  const { loading: notificationsLoading, data: notificationsData } =
    useQuery<UserNotificationsData, UserNotificationsVars>(
      USER_NOTIFICATIONS_QUERY, {
        notifyOnNetworkStatusChange: true,
      })

  const isCurrentPath = (href: string): boolean => pathname.startsWith(basepath + '/' + href)

  const listItem = (text: string, href: string, icon: React.ReactNode, color?: string) => (
    <DrawerListItem
      text={text}
      href={href}
      icon={icon}
      color={color}
      selected={isCurrentPath(href)}
      component={Link}
    />
  )

  const drawerContent = (
    <React.Fragment>
      <DrawerList>
        {listItem('Portafolio', 'portfolio', <AccountBalance />)}
        {listItem('Precios', 'market', <ShowChart />)}
        {listItem('Vender', 'withdraw', <Upload />)}
        {hasCrypto(marketAssets) && listItem('Depositar', 'deposit', <Download />)}
        {listItem('Convertir', 'swap', <SwapCalls />)}
        {listItem('Comprar', 'purchase', <CreditCard />)}
      </DrawerList>
      <DrawerDivider />
      <DrawerList>
        {listItem('Órdenes', 'bulk-purchases', <AttachMoney />)}
        {listItem('Cotizar', 'quote', <RequestQuote />)}
      </DrawerList>
      <DrawerDivider />
      <DrawerList>
        {listItem('Fondos', 'funds', <Business />)}
        {listItem('Comprar cuotas', 'fund-purchase', <CreditCard />)}
        {listItem('Vender cuotas', 'fund-sale', <Upload />)}
      </DrawerList>
      <DrawerDivider />
      <DrawerList>
        {listItem('Historial', 'history', <HistoryIcon />)}
        {listItem('Notificaciones', 'notifications', (
          <UserNotificationsIcon
            loading={notificationsLoading}
            userNotifications={notificationsData?.userNotifications}
          />
        ))}
        {listItem('Documentos', 'documents', <ShowChart />)}
      </DrawerList>
      <DrawerDivider />
      <DrawerList sx={{ marginBottom: 7 }}>
        {listItem('Soporte', 'support', <Mail />)}
      </DrawerList>
    </React.Fragment>
  )

  return (assetsLoading) ? (
    <Loading />
  ) : (
    <Router basepath={basepath}>
      <Layout
        path='/'
        drawerContent={drawerContent}
      >
        <Redirect
          noThrow
          from='/'
          to='portfolio'
        />
        <Documents path='documents' />
        <BulkPurchases path='bulk-purchases' />
        <Quote path='quote' />
        <Portfolio path='portfolio' />
        <PortfolioDetails path='portfolio/:symbol' />
        <Funds path='funds' />
        <FundsDetails path='funds/:id' />
        <Market path='market' />
        <MarketDetails path='market/:symbol' />
        <Withdraw path='withdraw' />
        <WithdrawBank path='withdraw/bank' />
        <BankAccounts path='bank' />
        <WithdrawalAddress path='withdrawal-address' />
        <WithdrawBlockchain path='withdraw/blockchain' />
        <Deposit path='deposit' />
        <Deposit path='deposit/:symbol' />
        <Swap path='swap' />
        <Purchase path='purchase' />
        <FundPurchase path='fund-purchase' />
        <FundSale path='fund-sale' />
        <History path='history' />
        <Notifications path='notifications' />
        <Signature path='signature' />
        <Support path='support' />
        <Security path='security' />
        <NotFound default />
      </Layout>
    </Router>
  )
}

export default App
