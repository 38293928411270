import * as React from 'react'

import { Grid } from '@mui/material'

import { AppContainer, SeoHeaders } from 'shared/components'

import { WithdrawBlockchainForm } from '../withdraw_blockchain_form'

import type { RouteComponentProps } from '@reach/router'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const WithdrawBlockchain = (props: RouteComponentProps) => {

  return (
    <React.Fragment>
      <SeoHeaders title='Retirar al blockchain' />
      <Grid
        container
        maxWidth='sm'
        mx='auto'
      >
        <AppContainer sx={{ p: 3 }}>
          <WithdrawBlockchainForm />
        </AppContainer>
      </Grid>
    </React.Fragment>
  )
}

export default WithdrawBlockchain
