import * as React from 'react'

import { useQuery } from '@apollo/client'

import { BulkPurchasesDashboard, SeoHeaders } from 'shared/components'
import {
  ACCOUNT_INFORMATION_QUERY,
  BULK_PURCHASES_OVERVIEW_QUERY,
} from 'shared/queries'

import type { RouteComponentProps } from '@reach/router'
import type {
  AccountInformationData,
  AccountInformationVars,
  BulkPurchasesOverviewData,
  BulkPurchasesOverviewVars,
} from 'shared/queries'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const BulkPurchases = (props: RouteComponentProps) => {
  const { loading: overviewLoading, data: overviewData } =
    useQuery<BulkPurchasesOverviewData, BulkPurchasesOverviewVars>(BULK_PURCHASES_OVERVIEW_QUERY)

  const { loading: accountLoading, data: accountData } =
    useQuery<AccountInformationData, AccountInformationVars>(ACCOUNT_INFORMATION_QUERY)

  const isVerified = accountData?.accountInformation.accountStatus === 'IDENTITY_VERIFIED'

  const bulkPurchasesOverview = overviewData?.bulkPurchasesOverview

  return (
    <React.Fragment>
      <SeoHeaders title='Órdenes' />
      <BulkPurchasesDashboard
        loading={overviewLoading || accountLoading}
        bulkPurchasesOverview={bulkPurchasesOverview}
        withBankTransferDialog={isVerified}
        withBankDepositSimulator
      />
    </React.Fragment>
  )
}

export default BulkPurchases
